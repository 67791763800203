<template>
  <div class="content" v-html="content">

  </div>
</template>

<script>
import { getProtocol } from '@/api/agree'
export default {
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    getProtocol({ type: 1 }).then(res => {
      if (res.data) {
        this.content = res.data.context
      }
    })
  }
}
</script>

<style scoped>
  .content {
    padding: 100px 30px 120px 30px;
  }
</style>
