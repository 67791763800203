<template>
  <div class="hello">
    <img class="bg" src="../assets/bg/contact.png">
    <div class="wrap">
      <div class="title">联系我们</div>
      <div class="detail">
        <div>公司名字：成都三禧科技有限公司</div>
        <div>电话号码：028-86762552</div>
        <div>官方邮箱：support@3clife.com</div>
        <div>客服热线：028-86662552</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style lang="scss" scoped>
.hello {
  width: 100%;
  height: 100%;
  position: relative;
}
.bg {
  width: 100%;
  height: 100%;
}
.wrap {
  position: absolute;
  height: 100%;
  top: 30%;
  left: calc(50% - 362px);
  width: 100%;
  text-align: center;
  width: 713px;
  height: 247px;
  background: rgba($color: #fff, $alpha: 0.7);
  border-radius: 24px;
  .title {
    font-size: 28px;
    font-weight: bold;
    margin: 50px;
  }
   .detail {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-left: 80px;
      div {
        width: 300px;
        font-size: 18px;
        color:#434348;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
      }
    }
}
</style>
