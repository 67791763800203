<template>
  <div id="app">
    <appHeader />
      <router-view />
    <appFooter @openBox="openBox" />
    <el-dialog :visible.sync="dialogTableVisible">
      <div style="height:50vh;overflow-y:auto;" v-html="content" />
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="dialogTableVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import appHeader from '../src/components/common/header.vue'
import appFooter from '../src/components/common/footer.vue'
export default {
  name: 'App',
  components: {
    appHeader,
    appFooter
  },
  data() {
    return {
      content: '',
      dialogTableVisible: false
    }
  },
  methods: {
    openBox(data) {
      this.dialogTableVisible = !this.dialogTableVisible
      this.content = data
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__footer {
  text-align: center;
  /deep/ .el-button--danger:hover {
    background:#ff2323;
    opacity: .8;
  }
  /deep/ .el-button--danger {
    background:#ff2323;
  }
}
/deep/ .el-dialog__header .el-dialog__headerbtn {
  border-radius: 50%;
  border: 2px solid #909399;
  width: 26px;
  height: 26px;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  /deep/ .el-icon-close {
    height: 16px;
    font-weight: bold;
  }
  &:hover {
    border: 2px solid#ff2323;
    .el-icon-close {
      color: #ff2323;
    }
  }
}
</style>

