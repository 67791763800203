<template>
  <div :class="opcity?'opcity':'opcity1'" class="appHeader">
    <div>
      <img class="logo" src="../../assets/icon/logo.png" alt="">
    </div>
    <div class="icon">
      <div>
        <el-tooltip content="首页" placement="bottom" effect="light">
          <i :class="url=== 'home'?'icon11':''" class="icon1" @click="goPage(1)" />
        </el-tooltip>
        <el-tooltip content="下载" placement="bottom" effect="light">
          <i :class="url=== 'downLoad'?'icon22':''" class="icon2" @click="goPage(2)" />
        </el-tooltip>
        <el-tooltip content="联系我们" placement="bottom" effect="light">
          <i :class="url=== 'contact'?'icon33':''" class="icon3" @click="goPage(3)" />
        </el-tooltip>
        <el-tooltip content="商城介绍" placement="bottom" effect="light">
          <i :class="url=== 'introduce'?'icon44':''" class="icon4" @click="goPage(4)" />
        </el-tooltip>
        <el-tooltip content="商城入驻" placement="bottom" effect="light">
          <i :class="url=== 'enter'?'icon55':''" class="icon5" @click="goPage(5)" />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      opcity: true
    }
  },
  computed: {
    url() {
      if (this.$route.path === '/downLoad') {
        return 'downLoad'
      } else if (this.$route.path === '/contact') {
        return 'contact'
      } else if (this.$route.path === '/introduce') {
        return 'introduce'
      } else if (this.$route.path === '/enter') {
        return 'enter'
      } else {
        return 'home'
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // 获取滚动时的高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 0) {
        this.opcity = false
      } else {
        this.opcity = true
      }
    },
    goPage(num) {
      if (num === 1) {
        this.$router.push('/')
      } else if (num === 2) {
        this.$router.push('/downLoad')
      } else if (num === 3) {
        this.$router.push('/contact')
      } else if (num === 4) {
        this.$router.push('/introduce')
      } else if (num === 5) {
        this.$router.push('/enter')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .opcity1 {
    background: rgba($color: #000, $alpha: .5);
  }
  .opcity {
    background: rgba($color: #fff, $alpha: 0.2);
  }
  .appHeader {
    height: 100px;
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    display: flex;
    z-index: 10;
    div {
      width: 50%;
    }
    .icon {
      box-sizing: border-box;
      &>div {
        margin-left: 16%;
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        padding: 25px 0;
      }
      i {
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &:hover {
          cursor: pointer;
        }
      }
      .icon1 {
        background-image: url('../../assets/icon/home.png');
        &:hover {
          background-image: url('../../assets/icon/homeHover.png') !important;
        }
      }
      .icon2 {
        background-image: url('../../assets/icon/downLoad.png');
        &:hover {
          background-image: url('../../assets/icon/downLoadHover.png') !important;
        }
      }
      .icon3 {
        background-image: url('../../assets/icon/contact.png');
        &:hover {
          background-image: url('../../assets/icon/contactHover.png') !important;
        }
      }
      .icon4 {
        background-image: url('../../assets/icon/introduce.png');
        &:hover {
          background-image: url('../../assets/icon/introduceHover.png') !important;
        }
      }
      .icon5 {
        background-image: url('../../assets/icon/enter.png');
        &:hover {
          background-image: url('../../assets/icon/enterHover.png') !important;
        }
      }
      .icon11 {
        background-image: url('../../assets/icon/homeHover.png') !important;
      }
      .icon22 {
        background-image: url('../../assets/icon/downLoadHover.png') !important;
      }
      .icon33 {
        background-image: url('../../assets/icon/contactHover.png') !important;
      }
      .icon44 {
        background-image: url('../../assets/icon/introduceHover.png') !important;
      }
      .icon55 {
        background-image: url('../../assets/icon/enterHover.png') !important;
      }
    }
    .logo {
      margin-top: 12px;
      height: 73px;
      width: 86px;
      margin-left: 370px;
    }
  }
</style>

