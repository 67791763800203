import axios from 'axios'
import { Message } from 'element-ui'
// 创建Axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests 跨域请求时发送cookies
  timeout: 10000
})

// request interceptor 请求拦截器
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error 处理请求错误
    console.log(error) // for debug
    return Promise.reject('error')
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return response.data
    }
    if (res.code !== 200) {
      // 通用错误
      Message({
        message: res.msg || '请求失败',
        type: 'error'
      })
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误(400)'
          break
        case 401:
          error.message = '请求错误(401)'
          break
        case 403: error.message = '拒绝访问(403)'; break
        case 404: error.message = '请求出错(404)'; break
        case 408: error.message = '请求超时(408)'; break
        case 500: error.message = '服务器错误(500)'; break
        case 501: error.message = '服务未实现(501)'; break
        case 502: error.message = '网络错误(502)'; break
        case 503: error.message = '服务不可用(503)'; break
        case 504: error.message = '网络超时(504)'; break
        case 505: error.message = 'HTTP版本不受支持(505)'; break
        default: error.message = `连接出错(${error.response.status})!`
      }
    } else {
      error.message = '连接服务器失败!'
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
