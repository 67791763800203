<template>
  <div class="appFooter">
    <div class="canClick">
      <span @click="openBox(0)">用户协议</span>|
      <span @click="openBox(1)">隐私政策</span>|
      <span @click="$router.push('/enter')">商城入驻</span>|
      <span @click="$router.push('/contact')">联系我们</span>
    </div>
    <div>
      <span>{{ year }}@3Clife</span>|
      <!-- <span>京ICP16016397号-3</span>| -->
      <span>成都三禧科技有限公司</span>
      <!-- <span>
        <a class="link" rel="nofollow" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"> 蜀ICP备20021958号-1</a>
      </span> -->
    </div>
    <!-- <div class="canClick">
      <img class="police" src="../../assets/icon/police.png" alt="">
      <span>
        <a rel="nofollow" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"> 蜀公网安备110000020022046号</a>
      </span>
    </div> -->
    <div style="margin:0 auto; padding:6px 0;">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010502011240" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../../assets/icon/police.png" style="float:left;"><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">川公网安备 51010502011240号</p></a>
      <a rel="nofollow" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">|&nbsp;&nbsp;&nbsp;蜀ICP备20021958号-1</p></a>
    </div>
  </div>
</template>
<script>
import { getProtocol } from '@/api/agree'
export default {
  data() {
    return {
      content0: '',
      content1: ''
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  },
  mounted() {
    getProtocol({ type: 0 }).then(res => {
      if (res.data) {
        this.content0 = res.data.context
      }
    })
    getProtocol({ type: 1 }).then(res => {
      if (res.data) {
        this.content1 = res.data.context
      }
    })
  },
  methods: {
    openBox(num) {
      const content = num === 0 ? this.content0 : this.content1
      this.$emit('openBox', content)
    }
  }
}
</script>
<style lang="scss" scoped>
  .appFooter {
    border-top: 1px solid #f4f4f4;
    height: 120px;
    background: rgba($color: #fff, $alpha: 1);
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    z-index: 10;
    .canClick {
      span:hover {
        color: rgb(248, 6, 6);
        cursor: pointer;
      }
    }
    div {
      text-align: center;
      &:first-child{
        margin-top: 18px;
      }
      &:last-child{
        margin-top: 3px;
      }
      span {
        display: inline-block;
        margin:5px;
      }
      .police {
        vertical-align: middle;
        width: 28px;
        height: 26px;
      }
    }
  }
  .link:hover {
    color: rgb(248, 6, 6);
  }
</style>
