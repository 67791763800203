<template>
  <div class="hello">
    <img class="bg" src="../assets/bg/downLoad.png">
    <img class="goods" src="../assets/bg/goods.png">
    <div class="wrap">
      <div class="title">
        3Clife商城 这里更懂你
      </div>
      <div class="desc">
        轻松生活  极致品质
      </div>
      <div class="down">
        <div class="imgDown">
          <div :class="type === 'android'?'selectedBg':''" style="margin-top:60px;" @click="change('android')">
            <img src="../assets/icon/app.png" alt="">Android版下载
          </div>
          <div :class="type === 'weChat'?'selectedBg':''" @click="change('weChat')">
            <img src="../assets/icon/weChat.png" alt="">微信小程序
          </div>
        </div>
        <div style="margin-top:60px;margin-left:20px">
          <img v-show="type === 'android'" class="appDown" src="../assets/icon/appDownLoad.png" alt="">
          <img v-show="type === 'weChat'" class="appDown" src="../assets/icon/weChatCode.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Download',
  data() {
    return {
      type: 'android'
    }
  },
  methods: {
    change(str) {
      this.type = str
    }
  }
}
</script>

<style lang="scss" scoped>
.hello {
  width: 100%;
  height: 100%;
  position: relative;
}
.bg {
  width: 100%;
  height: 100%;
}
.goods {
  position: absolute;
  top: 130px;
  right: 60px;
}
.wrap {
  position: absolute;
  padding: 100px 50% 120px 15%;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  color: #fff;
  .title {
    font-size: 54px;
    margin-top: 143px;
  }
  .desc {
    font-size: 36px;
    margin-top: 20px;
  }
  .down {
    display: flex;
    justify-content: flex-start;
    .imgDown {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      div {
        width: 309px;
        height: 104px;
        background: #fff;
        border-radius: 12px;
        margin-top: 16px;
        color: #363535;
        font-size: 24px;
        line-height: 104px;
        img {
          vertical-align: middle;
          margin-right: 10px;
          margin-left: 32px;
        }
      }
    }
  }
}
.appDown {
  width: 224px;
  height: 224px;
  border-radius: 14px;
}
.selectedBg {
  background: rgb(251, 231, 229) !important;
}
</style>
