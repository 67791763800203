import Vue from 'vue'
import Router from 'vue-router'
import index from '@/components/index'
import contact from '@/components/contact'
import downLoad from '@/components/downLoad'
import enter from '@/components/enter'
import introduce from '@/components/introduce'
import secret from '@/components/secret'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: index
    },
    {
      path: '/contact',
      name: 'contact',
      component: contact
    },
    {
      path: '/downLoad',
      name: 'downLoad',
      component: downLoad
    },
    {
      path: '/enter',
      name: 'enter',
      component: enter
    },
    {
      path: '/introduce',
      name: 'introduce',
      component: introduce
    },
    {
      path: '/secret',
      name: 'secret',
      component: secret
    }
  ]
})
