<template>
  <div class="hello">
    <img class="bg" src="../assets/bg/introduce.png">
    <div class="wrap">
      <div class="title">About Mall</div>
      <div class="title1">商城介绍</div>
      <div class="detail">
        3C生活商城是有成都三禧科技有限公司开发的线上商城，致力于服务小微企业及个人的线上电商平台，让线上工具成为线下经经营的助力器3C生活商
        城是有成都三禧科技有限公司开发的线上商城，致力于服务小微企业及个人的线上电商平台，让线上工具成为线下经经营的助力器3C生活商城是有成都
        三禧科技有限公司开发的线上商城，致力于服务小微企业及个人的线上电商平台，让线上工具成为线下经经营的助力器3C生活商城是有成都三禧科技有
        限公司开发的线上商城，致力于服务小微企业及个人的线上电商平台，让线上工具成为线下经经营的助力器。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduce'
}
</script>

<style lang="scss" scoped>
.hello {
  width: 100%;
  position: relative;
  padding-bottom: 500px;
}
.bg {
  width: 100%;
  height: 746px;
}
.wrap {
  position: absolute;
  height: 100%;
  top: 680px;
  left: calc(50% - 600px);
  width: 100%;
  text-align: left;
  width: 1200px;
  height: 353px;
  background: #ff4d4d;
  border-radius: 24px;
  color: #fff;
  padding: 40px;
  .title {
    font-size: 28px;
    font-weight: bold;
  }
  .title1 {
    font-size: 26px;
    margin-top: 10px;
  }
   .detail {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      line-height: 30px;
      margin-top: 40px;
      // padding-left: 80px;
      div {
        width: 300px;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
      }
    }
}
</style>
