<template>
  <div class="hello">
    <img class="bg" src="../assets/bg/index.png">
    <div class="wrap">
      <el-carousel :interval="3000" type="card" :height="bannerHeight">
        <el-carousel-item v-for="item in 3" :key="item">
          <img v-show="item === 1" :style="'height:'+ bannerHeight" src="../assets/banner/1.png" alt="">
          <img v-show="item === 2" :style="'height:'+ bannerHeight" src="../assets/banner/2.png" alt="">
          <img v-show="item === 3" :style="'height:'+ bannerHeight" src="../assets/banner/3.png" alt="">
        </el-carousel-item>
      </el-carousel>
      <div class="tip1">
        八大专区 品类齐全
      </div>
      <div class="tip2">
        新品发布  商城活动  热销产品
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      bannerHeight: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.bannerHeight = (window.innerHeight - 340) + 'px'
    })
  }
}
</script>

<style lang="scss" scoped>
.hello {
  width: 100%;
  height: 100%;
  position: relative;
}
.bg {
  width: 100%;
  height: 100%;
}
.wrap {
  position: absolute;
  padding: 100px 17% 120px 17%;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  /deep/ .el-carousel__mask {
    background: rgba($color: #fff, $alpha: 0);
  }
  .tip1 {
    margin-top: 10px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .tip2 {
    color: #fff;
  }
}
</style>
