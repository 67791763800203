<template>
  <div class="hello">
    <img class="bg" src="../assets/bg/enter.png">
    <div class="wrap">
      <div class="title">3C生活商城合作介绍</div>
      <div class="detail">
        1、甲方系一家专门从事计算机网络通讯系统的技术开发、技术转让、技术咨询、技术服务、销售的公司，拥有3C生活商城线上
        平台（指3C生活网站（域名为www.3clife.com，包括PC、Wap）、客户端、小程序及其他应用程序的所有权和经营权。（注：
        本协议中提及“3C生活商城”时均可指向3C生活网站、客户端、小程序及其他应用程序中的一种或多种形态）
        <br>
        2、乙方系具有完全行为能力的法人或个人，有意与甲方共同进行3C生活商城的宣传、推广，促进网站线上产品的销售。

        现甲、乙双方本着友好合作、互惠互利的原则，就双方合作推广相关事宜，签署并实施本合作协议。
        <br>
        一、合作目标
        <br>
        1、确立优势互补、协作互利的战略合作伙伴关系。
        <br>
        2、共同实施3C生活商城的宣传、推广，促进网站线上产品的销售。
        <br>
        二、合作内容
        <br>
        1、乙方享有在3C生活商城带有乙方专属标识的店铺，并享有该店铺的专属二维码，第三方用户可通过扫描二维码注册并购买该店铺
        线上商品，乙方根据该店铺线上商品销售数量及金额提取相应佣金。
        <br>
        2、乙方进行店铺推广时，使用的是乙方自行拥有的、或依法合法取得完整经营权或推广权的互联网平台或手机个人网站、手机联盟
        网站、其他社交软件等。
        <br>
        三、合作期限
        <br>
        1、合作期限为：合作期限为一年，自本协议签字生效之日起算。合作期满后，本协议自动展期一年，最多自动展期两次。上述期限届
        满后，双方如有继续合作的愿望，以本协议为基础重新签订合作协议。
        <br>
        2、甲乙双方因自身原因需要单方面终止协议的，需要提前三十个工作日以书面形式或电子邮件、微信、QQ等电子通讯工具通知对方。
        <br>
        四、甲方的权利和义务
        <br>
        1、甲方负责向乙方提供在3C生活商城带有乙方专属标识的店铺及店铺专属二维码。
        <br>
        2、甲方须为乙方提供合作推广所需要的相关资质证明文件及其他推广材料，积极配合乙方开展合作推广活动。
        <br>
        3、甲方提供信息合法，不得出现法律禁止的内容。
        <br>
        4、对于通过乙方店铺所销售的线上商品，甲方向乙方开放销售数据查询平台，乙方可以自行进行销售数据查询。乙方对数据如有疑问
        可向甲方提出异议，双方就各自数据予以核对，双方根据最终确认一致的数据进行结算。
        <br>
        5、甲方根据本协议中的结算条款，按照约定时间及时向乙方结算佣金。
        <br>
        五、乙方的权利和义务
        <br>
        1、乙方应积极就乙方在3C生活商城的店铺进行市场推广。
        <br>
        2、乙方应及时向甲方反馈3C生活商城店铺市场推广的相关进展、市场推广建议与意见，以便甲方更好协助乙方宣传及推广。
        <br>
        3、乙方应遵守国家相关法律法规，合法开展市场推广活动，乙方不得通过非法手段进行不正当竞争，乙方不得以虚假和引人误解的内容
        欺骗、误导第三方用户，若给第三方用户造成损失的，由乙方承担全部责任。
        <br>
        4、乙方负有保守商业秘密的义务，不得对外泄露本协议以及因履行本协议所知悉的甲方商业秘密。
        <br>
        5、乙方不得在推广时擅自将3C生活商城（包括但不限于软件、名称、格式、数据、图片等）进行任何修改、拆分、分解或二次开发。
        <br>
        6、乙方不得将甲方提供的甲方产品资料（包括但不限于计算机程序、相关文档、屏幕截图等）用于本协议约定以外的宣传、推广。
        <br>
        7、乙方须在甲方提供的3C生活商城进行有效注册。
        <br>
        8、乙方有权按本协议约定的方式收取佣金。
        <br>
        9、乙方应按照所提取佣金的金额向甲方提供相同金额的服务费发票。
        <br>
        六、关于佣金的结算
        <br>
        1、佣金结算方式：
        <br>
        对于乙方店铺销售且未发生退货的订单，以订单利润作为佣金计提基数按甲方系统设定比例，由甲方向乙方进行结算。
        <br>
        结算数据以甲方的数据为准，如乙方自行统计的数据与甲方平台统计的数据有误差，则双方核对数据后对最终金额予以确定。
        <br>
        2、佣金提现方式
        <br>
        第三方用户订单完成七日后，乙方可按照甲方平台系统自动显示的可提现佣金金额自行提现。
        <br>
        七、违约责任
        <br>
        1、甲乙双方应当诚实信用、全面履行本协议。任何乙方违反本协议约定，给对方造成损失的，应当承担违约责任，并赔偿由此给守约方造
        成的损失。
        <br>
        2、若乙方违反本协议第五条第3、4、5、6款的约定，则甲方有权立即解除本协议，且不支付乙方任何佣金，并要求乙方承担由此给甲方带
        来的一切损失。
        <br>
        八、争议解决
        <br>
        甲、乙双方本着友好协商的原则，解决本协议未尽事宜，未尽事宜可向甲方注册地人民法院起诉。
        <br>
        九、其他
        <br>
        1、本协议一式_贰_份，甲、乙双方各执__壹__份，具有同等法律效力。
        <br>
        2、本协议未尽事宜甲乙双方另行签订补充协议，补充协议与本协议具有同等法律效力。
        <br>
        3、本合同自双方签字或盖章之日起生效。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Enter'
}
</script>

<style lang="scss" scoped>
.hello {
  width: 100%;
  position: relative;
  padding-bottom: 500px;
  background: #f4f4f4;
}
.bg {
  width: 100%;
  height: 861px;
}
.wrap {
  position: absolute;
  top: 500px;
  left: calc(50% - 600px);
  width: 100%;
  text-align: left;
  width: 1200px;
  background: #ffffff;
  border-radius: 24px;
  color: #333;
  padding: 140px;
  margin-bottom: 160px;
  .title {
    font-size: 28px;
    font-weight: bold;
  }
  .title1 {
    font-size: 26px;
    margin-top: 10px;
  }
  .detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 30px;
    margin-top: 40px;
    div {
      width: 300px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: left;
    }
  }
}
</style>
